// L'URL de l'API peut être modifiée dans le fichier public/config.js
export const API_URL = window.config.apiUrl;

// Vérification de l'URL du proxy en production
// Sauf cas extrême, l'URL ne DOIT PAS pointer sur localhost
if (
	API_URL.indexOf('localhost') > -1 &&
	window.location.hostname !== "localhost" &&
	sessionStorage.getItem('localhost') !== "true") {
	sessionStorage.setItem('localhost', window.confirm("L'API est configuré en local ! Cela peut provoquer des erreurs réseau.\nCliquez sur \"OK\" pour ne plus voir ce message."));
}

const KEY = 'API_TOKEN';

export const setToken = token => localStorage.setItem(KEY, token);

export const getToken = () => localStorage.getItem(KEY);

export const isLoggedIn = () => getToken() !== '' && getToken() !== null && getToken() !== undefined;

export const removeToken = () => localStorage.removeItem(KEY);
